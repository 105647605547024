Skip to content
Search or jump to…
Pull requests
Issues
Marketplace
Explore

@alecAWD
dcorapi
/
FieldOrderz
Private
Code
Issues
Pull requests
4
Actions
Projects
Wiki
Security
Insights
FieldOrderz/web-client/src/Generic.css
@Scain3
Scain3 style ServiceManager.js
Latest commit b2a6c98 on Oct 7
 History
 3 contributors
@maverickchen@Scain3@dcorapi
442 lines (375 sloc)  7.03 KB

:root {
  --primary-lightest: #596392;
  --primary-light: #3e4471;
  --primary-darker:#2c3350;
  --primary-darkest:#9196a3;
  --white:#ffffff;
  --lightgray:#cfd0da;
  --darkgray:#adafbc;
  --primary-blue:#003E70;
  --secondary-orange: #F15A29;
  --secondary-red: #ec5747;
  --secondary-yellow: #f6b81c;
  --secondary-green: #5ec093;
  /* margin-left:250px; */
}

.button-blue {
  border-color: var(--secondary-orange);
  background: var(--secondary-orange);
  color: var(--white);
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 4px;
  font-weight: 400;
}

.button-outline-blue {
  border-color: var(--primary-blue);
  border-width: 1px;
  border-style: solid;
  background: var(--white);
  color: var(--primary-blue);
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 4px;
  font-weight: 400;
}

.p1 {
  border-color: var(--primary-lightest);
  background: var(--primary-lightest);
  color: var(--white);
}

.p2 {
  border-color: var(--primary-light);
  background: var(--primary-light);
  color: var(--white);
}
.p3 {
  border-color: var(--primary-darker);
  background: var(--primary-darker);
  color: var(--white);
}
.p4 {
  border-color: var(--primary-darkest);
  background: var(--primary-darkest);
  color: var(--white);
}

.n1 {
  border-color: var(--white);
  background: var(--white);
  color: var(--primary-darkest);
}

.n2 {
  border-color: var(--lightgray);
  background: var(--lightgray);
  color: var(--primary-darkest);
}

.n3 {
  border-color: var(--darkgray);
  background: var(--darkgray);
  color: var(--primary-darkest);
}

.s1 {
  border-color: var(--secondary-orange);
  background: var(--secondary-orange);
  color: var(--primary-darkest);
  border-radius: 8px
}

.s2 {
  border-color: var(--secondary-red);
  background: var(--secondary-red);
  color: var(--primary-darkest);
}

.s3 {
  border-color: var(--secondary-yellow);
  background: var(--secondary-yellow);
  color: var(--primary-darkest);
}

.s4 {
  border-color: var(--secondary-green);
  background: var(--secondary-green);
  color: var(--primary-darkest);
}

.Area {
  /* text-align: center; */
  border-radius: 5px;
  margin: 5px;
  padding: 2px;
}

.spaced {
  margin: 12px;
  padding: 20px;
}

.bordered {
  border: 1.5px solid #dadada;
  border-radius: 4px;
}

 @media only screen and (max-width: 800px) {
  .modal-form {
    width: 100%;
  }
}

.modal-form {
  width: 750px;
}

.logo-container {
  position: relative;
  background: white;
  width: 180px;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 10px;
  object-fit: contain;
  padding: 5px;
}

.logo {
  color: black;
  object-fit: contain;
  height: inherit;
  width: inherit;
  border-radius: inherit;
}

.icon-container {
  position: relative;
  background: white;
  width: 64px;
  height: 64px;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 10px;
  object-fit: cover;
  padding: 5px;
}

.h-field {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.flex-spaced-evenly {
  justify-content: space-around;
}

.service {
  /* background-color: var(--secondary-yellow); */
  color: #000000;
  font-size: 16px;
  line-height: 20px;

}

.catalog {
  background-color: var(--secondary-green);
  color: var(--primary-darkest);
}

.app-version {
  font-size: 0.8em;
  flex-direction: row-reverse;
  display: flex;
  padding-right: 1em;
  font-weight: normal;
}

.text {
  background-color: white;
  color: #494949;
  border-radius: 26px;
  border: 2px solid #dadada;
  font-size: 18px;
  padding: 10px;
  margin: 10px;
  text-align: center;
  font-family: 'Proxima Nova', 'Open Sans';
  font-weight: bold;
}

.file-input-hidden {
  opacity: 0;
  position: absolute;
  pointer-events: none;
  /* alternative to pointer-events, compatible with all browsers, just make it impossible to find */
  width: 1px;
  height: 1px;
}

 .text:hover {
  border-color: #8f8f8f;
}

.tab-list {
  /* border-bottom: 1px solid #aaa; */
  /* margin: 0 0 10px; */
  padding: 0;
}

.tab {
  display: inline-block;
  background: var(--primary-light);
  color: var(--white);
  border: 1px solid transparent;
  border-bottom: none;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
  border-radius: 10px 10px 0 0;
  margin-right: 5px;
  transition-duration: 200ms;
  transition-timing-function: linear;
}

.tab-panel {
  display: none;
  background: var(--primary-lightest);
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 10px;
  min-height: 300px;
  margin-bottom: 20px;
}

.tab-panel-selected {
  display: block;
}

.tab-selected {
  background: var(--primary-lightest);
}

::placeholder {
  color: gray;
  font-size: medium;
  font-weight: 100px;
  font-style: italic;
}

.flex-v {
  display: flex;
  flex-direction: column;
}

.wrap {
  flex-flow: wrap;
}

.scroll-x {
  overflow-x: scroll;
}

.scroll-y {
  overflow-y: scroll;
}

.aligned {
  align-items: center;
}

.separated {
  justify-content: space-between;
}

.v-center {
  justify-content: center;
}

.h-center {
  align-items: center;
  text-align: center;
}

.flex-h {
  display: flex;
  flex-direction: row;
}

.flex-grow {
  flex-grow: 3;
}

.fill {
  height: 100%;
  width: 100%;
}

.flex-v-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.add {
  color: var(--white);
}

.success {
  color: var(--secondary-green);
}

.delete {
  color: var(--secondary-red);
}

.dark-overlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  opacity: 0.5;
}

.overlay-c {
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.overlay-ur {
  position: absolute;
  right: 10%;
  top: 5%;
}

.clickable {
  cursor: pointer;
}

.clickable:hover {
  filter: brightness(115%);
}

.dragging {
  -webkit-box-shadow: 0px 10px 22px -12px rgba(0,0,0,0.7);
  -moz-box-shadow: 0px 10px 22px -12px rgba(0,0,0,0.7);
  box-shadow: 0px 10px 22px -12px rgba(0,0,0,0.7);
  z-index: 10000;
  border: 2px solid #858585;
}

.draggable {
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  width: -webkit-fill-available;
}

.dropArea {
  min-width: 150px;
  min-height: 200px;
}

.unsure {
  border-style: dotted;
  border-width: 2px;
}

.cutout {
  -webkit-box-shadow: inset 0px 10px 46px -3px rgba(0,0,0,0.68);
  -moz-box-shadow: inset 0px 10px 46px -3px rgba(0,0,0,0.68);
  box-shadow: inset 0px 10px 46px -3px rgba(0,0,0,0.68);
}

.active {
  -webkit-box-shadow: 0px 10px 22px -12px rgba(0,0,0,0.7);
  -moz-box-shadow: 0px 10px 22px -12px rgba(0,0,0,0.7);
  box-shadow: 0px 10px 22px -12px rgba(0,0,0,0.7);
}

.inactive {
  background-color: #f1f1f1;
  color: #9c9c9c;
  opacity: 0.5;
}

.end {
  justify-content: flex-end;
}

.plump {
  border-radius: 10px;
  border-width: 2px 2px 4px;
  border-style: solid;
}

.toolbtn {
  width: 150px;
  height: 50px;
  padding: 0px 5px;
}

.gray {
  border-color: rgb(189, 189, 189);
  background-color: rgb(202, 202, 202);
}

.big {
  font-size: 16px;
}

.medium {
  font-size: 12px;
}
